@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,600;1,700;1,800;1,900&display=swap');


:root {
  --background: #f2f5f7;
  --text-primary: #2f4770;
  --text-secondary: #647780;
  --white: #ffffff;
}

body {
  margin: 0;
  font-family: 'Montserrat', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* accordion styles */
h1 {
  font-size: 42px;
  color: var(--text-primary);
  margin: 0px;
  margin-bottom: 30px;
}

.accordion-simple > .active {
  display: block;
}

.accordion__faq .inactive{
  display: none;
}

.accordion__faq > div{
  background-color: var(--white);
  margin-bottom: 20px;
  padding: 0px 15px;
  border-radius: 5px;
  cursor: pointer;
  border-bottom: 1px solid rgba(169, 169, 169, 0.237);
  box-shadow: 0 3px 5px rgba(0,0,0,0.1);
}

.accordion__title{
  color: var(--text-secondary);
  padding-bottom: 20px;
  font-weight: 500;
}

.accordion__faq-heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 3px 10px 3px;
}
.accordion__faq-heading .active{
  color: var(--text-primary);
}

.accordion__faq-heading h3{
  color: var(--text-secondary);
  font-weight: 600;
}

.accordion__faq p {
  margin: 0px;
  padding-bottom: 30px;
  color: var(--text-secondary);
  line-height: 1.4;
}
