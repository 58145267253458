
.navbar{
    box-shadow: 2px 3px 4px rgba(0,0,0,0.06);
}
.navbar.scrollBg{
    background-color: rgba(55, 55, 55, 0.815) !important;
}

/* Admin Product List Screen Styles */
.productList{
    flex: 4;
}

.productListItem{
    display: flex;
    align-items: center;
}

.productListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

.productListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.productListDelete{
    color: red;
    cursor: pointer;
}

.chart {
    margin: 20px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  
.chartTitle {
    margin-bottom: 20px;
}

/* Product Edit Screen Styles */
.product {
    flex: 4;
    padding: 20px;
  }
  
  .productTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .productAddButton {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .productTop {
    display: flex;
  }
  
  .productTopLeft,
  .productTopRight {
    flex: 1;
  }
  
  .productTopRight {
    padding: 20px;
    margin: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  
  .productInfoImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .productInfoTop {
    display: flex;
    align-items: center;
  }
  
  .productName {
    font-weight: 600;
  }
  
  .productInfoBottom {
    margin-top: 10px;
  }
  
  .productInfoItem {
    width: 150px;
    display: flex;
    justify-content: space-between;
  }
  
  .productInfoValue {
    font-weight: 300;
  }
  
  .productBottom {
    padding: 20px;
    margin: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  
  .productForm {
    display: flex;
    justify-content: space-between;
  }
  
  .productFormLeft {
    display: flex;
    flex-direction: column;
  }
  
  .productFormLeft > label {
    margin-bottom: 10px;
    color: gray;
  }
  
  .productFormLeft > input {
    margin-bottom: 10px;
    border: none;
    padding: 5px;
    border-bottom: 1px solid gray;
  }
  
  .productFormLeft >select{
    margin-bottom: 10px;
  }
  
  .productUploadImg{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .productFormRight{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .productUpload{
    display: flex;
    align-items: center;
  }
  
  .productButton{
    border: none;
    padding: 5px;
    border-radius: 5px;
    background-color: darkblue;
    color:white;
    font-weight: 600;
    cursor: pointer;
  }
  /* Product Edit Screen styles ends */



@media only screen and (max-width: 768px) {
    .top-bar{
      display: none;
    }
    nav{
        background: rgba(55, 55, 55, 0.815) !important;
    }
    footer{
        text-align: center;
    }
}
